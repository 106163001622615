import React, { Component } from 'react';
import Layout from '../components/Layout';
import {GiConversation} from 'react-icons/gi';
import {MdLocalPhone} from 'react-icons/md';
import { GoMail } from 'react-icons/go';
import {FaLine} from 'react-icons/fa';
import {Helmet} from 'react-helmet';
import Img from 'gatsby-image';
import {graphql} from 'gatsby';

class Contactus extends Component {
    render() {
        const { data } = this.props
        return (
            <div className="contactus">
                <Helmet>
                    <title>ติดต่อเรา</title>
                </Helmet>
                <Layout>
                    <div className="header-page">
                        <div className="header-page-text">
                            ติดต่อเรา
                        </div>
                        <div className="header-page-overay"></div>
                        <Img fluid={data.file.childImageSharp.fluid} alt="สอนเขียนโปรแกรม" className="header-page-img" />
                    </div>
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-12 text-center">
                                <h3><GiConversation /> ช่องทางการติดต่อ</h3>
                            </div>
                        </div>
                        <div className="row p-5">
                            <div className="col-12">
                                <h4><MdLocalPhone /> 080-6372314</h4>
                            </div>
                            <div className="col-12">
                                <h4><GoMail /> admin@bugyourdream.com</h4>
                            </div>
                            <div className="col-12">
                                <h4><FaLine /> @bugyourdream</h4>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default Contactus;

export const query = graphql`
query{
      file(relativePath: {eq: "contactus.jpg"}) {
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
  }  
`